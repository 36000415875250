import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Players from './Players';
import SelectCategories from './SelectCategories';
import Game from './Game';


export default function GameSetup() {

	const [page, setPage] = React.useState("players");
	const [players, setPlayers] = React.useState([]);
	const [categories, setCategories] = React.useState([]);

  const playerCallback = (direction, players) => {
    if (direction == "next"){
      setPlayers(players);
      setPage("categories");
    }
    if (direction == "previous"){
      setPage("home");
    }
  }

  const categoryCallback = (direction, categories) => {
    if (direction == "next"){
      setCategories(categories);
      setPage("game");
    }
    if (direction == "previous"){
      setPage("players");
    }
  }

  return (
  	<Box>
      {page == "players" ? <Players callback={playerCallback} /> : null}
      {page == "categories" ? <SelectCategories callback={categoryCallback} /> : null}
      {page == "game" ? <Game players={players} categories={categories} /> : null}
    </Box>
  )
}